import {
	ChangeDetectionStrategy,
	ChangeDetectorRef,
	Component,
	HostListener,
	Inject,
	LOCALE_ID,
	OnInit,
	PLATFORM_ID,
	Renderer2,
	ViewChild
} from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { DarkModeService, NavigationItem, NavigationList } from '@arematics/core/api';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { filter } from 'rxjs';
import { ResolverstateService } from './_service/resolverstate.service';
import { DOCUMENT, isPlatformServer } from '@angular/common';
import { BreakpointObserver } from '@angular/cdk/layout';
import { FooterComponent } from '@arematics/core/footer';
import { SeoService } from '@arematics/core/seo';

const defaultKeywords = 'Arematics, Learning, Courses, Programming, Java, eLearning';
const defaultDescription = $localize`:@@description:Discover Arematics Learning with our modern courses or take a look at our blog`;
const defaultType = 'website';
const defaultImage = 'https://www.arematics.com/assets/banner/banner.jpg';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
	title = 'Arematics';
	headerItems: NavigationItem[] = [];
	footerItems: NavigationList[] = [];
	footerSocials: NavigationItem[] = [];
	footerLegals: NavigationItem[] = [];
	loginOverlayItems: NavigationItem[] = [];
	accountOverlayItems: NavigationItem[] = [];
	cookiesAccepted: boolean = true;
	loading: boolean = false;
	localeAdjustmentsMade: boolean = false;
	mobile: boolean = false;
	darkMode: boolean = false;

	@ViewChild('footer', { static: true }) footer!: FooterComponent;

	constructor(
		private cookies: SsrCookieService,
		private router: Router,
		private activeRoute: ActivatedRoute,
		private ref: ChangeDetectorRef,
		private seo: SeoService,
		private rss: ResolverstateService,
		private breakpoint: BreakpointObserver,
		private renderer: Renderer2,
		private darkModeService: DarkModeService,
		@Inject(DOCUMENT) private document: Document,
		@Inject(LOCALE_ID) public locale: string,
		@Inject(PLATFORM_ID) private platformId: any
	) {
		this.breakpoint.observe(['(max-width: 767px)']).subscribe((result) => {
			this.mobile = result.matches;
			this.ref.markForCheck();
		});
		this.rss.state.subscribe((state) => {
			this.loading = state;
			this.ref.markForCheck();
		});
		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe((event) => this.seoUpdater());
		this.headerItems = [
			{ title: 'Courses', routerLink: '/course', icon: 'fa-graduation-cap' },
			{ title: 'Creator', routerLink: '/creator', icon: 'fa-pen-nib' },
			{ title: 'Blog', routerLink: '/blogs', icon: 'fa-bullhorn' },
			{ title: 'About', routerLink: '/about', icon: 'fa-map' },
			{ title: 'Support', routerLink: '/support', icon: 'fa-users' }
		];
		this.footerItems = [
			{
				title: $localize`:@@footer.pages.title:Overview`,
				content: [
					{
						title: $localize`:@@footer.pages.home:Homepage`,
						routerLink: '/',
						icon: ''
					},
					{
						title: $localize`:@@about.title:About us`,
						routerLink: '/about',
						icon: ''
					},
					{
						title: $localize`:@@account.title:Account`,
						routerLink: '/account',
						icon: ''
					}
				]
			},

			{
				title: $localize`:@@support.title:Support`,
				content: [
					{
						title: $localize`:@@support.title:Support`,
						routerLink: '/support',
						icon: ''
					}
				]
			}
		];
		this.footerSocials = [
			{ title: 'Youtube', url: 'https://youtube.com/@arematics', icon: 'fa-brands fa-youtube' },
			{ title: 'Instagram', url: 'https://www.instagram.com/arematics/', icon: 'fa-brands fa-instagram' },
			{ title: 'Twitter', url: 'https://twitter.com/arematics', icon: 'fa-brands fa-twitter' },
			{ title: 'TikTok', url: 'https://www.tiktok.com/@arematics', icon: 'fa-brands fa-tiktok' },
			{ title: 'Discord', url: 'https://discord.gg/RUHMt5Bac5', icon: 'fa-brands fa-discord' }
		];
		this.footerLegals = [
			{ title: $localize`:@@footer.imprint:Imprint`, routerLink: '/impressum/', icon: '' },
			{ title: $localize`:@@footer.privacy:Privacy`, routerLink: '/privacy/', icon: '' },
			{ title: $localize`:@@footer.tos:Terms of Service`, routerLink: '/tos/', icon: '' },
			{ title: $localize`:@@footer.recall:Revocation Rights`, routerLink: '/revocation/', icon: '' }
		];
		this.loginOverlayItems = [
			{ title: $localize`:@@overlay.bag:Bag`, icon: 'fa-solid fa-shopping-bag', routerLink: '/checkout' },
			{ title: $localize`:@@overlay.orders:Orders`, icon: 'fa-solid fa-box', routerLink: '/orders' }
		];
		this.accountOverlayItems = [
			{ icon: 'fa-solid fa-book', title: $localize`:@@overlay.yc:Your Courses`, routerLink: '/account/courses' },
			{ icon: 'fa-solid fa-gear', title: $localize`:@@overlay.account:Account`, routerLink: '/account' },
			...this.loginOverlayItems
		];
		this.cookiesAccepted = isPlatformServer(this.platformId) || this.cookies.get('cookie-accepted') === 'true';
		this.ref.markForCheck();
	}

	ngOnInit() {
		this.localeAdjustmentsMade =
			isPlatformServer(this.platformId) ||
			this.locale === this.footer.selectedLanguage ||
			this.isLocaleAdjustmentsMade();
		this.ref.markForCheck();
		this.footer.lgOptInChanged.subscribe(() => {
			this.localeAdjustmentsMade =
				isPlatformServer(this.platformId) ||
				this.locale === this.footer.selectedLanguage ||
				this.isLocaleAdjustmentsMade();
			this.ref.markForCheck();
		});
	}

	private seoUpdater() {
		this.rss.setState(false);
		this.loading = false;
		this.seo.enableCanonical();
		let route = this.activeRoute.snapshot;
		while (route.firstChild) {
			route = route.firstChild;
		}
		const data = route.pathFromRoot.map((r: any) => r.data).reduce((acc: any, data) => ({ ...acc, ...data }), {});
		const keywords = data?.meta?.keywords?.join(', ') || defaultKeywords;
		const title = this.replacePlaceholders(data?.meta?.title, data, '');
		const description = this.replacePlaceholders(data?.meta?.description, data, defaultDescription);
		const type = this.replacePlaceholders(data?.meta?.type, data, defaultType);
		const image = this.replacePlaceholders(data?.meta?.image, data, defaultImage);
		this.darkMode = data?.meta?.dark || false;
		this.setDarkMode(this.darkMode);
		if (title !== undefined) {
			this.seo.updateTitle(title);
		}
		this.seo.updateKeywords(keywords);
		this.seo.updateDescription(description);
		this.seo.update('og:type', type);
		this.seo.update('og:image', image);
	}

	setDarkMode(isDarkMode: boolean): void {
		isDarkMode
			? this.renderer.addClass(this.document.body.parentElement, 'dark-theme')
			: this.renderer.removeClass(this.document.body.parentElement, 'dark-theme');
	}

	private replacePlaceholders(text: string | undefined, data: any, defaultValue: any) {
		if (!text) {
			return defaultValue;
		}
		let updatedText = text;
		const regex = /{(.*?)}/g;
		let match;

		// Replace all strings that match the format '{placeholder}'
		while ((match = regex.exec(text)) !== null) {
			const [placeholder, key] = match;
			updatedText = updatedText.replace(placeholder, this.getNestedData(key, data, defaultValue));
		}

		return this.sanitizeHTML(updatedText) || defaultValue;
	}

	private sanitizeHTML(htmlString: string) {
		return htmlString.replace(/(<([^>]+)>)/gi, '');
	}

	private getNestedData(path: string, data: any, defaultValue: string) {
		const keys = path.split('.');
		let currentData = data;

		for (const key of keys) {
			if (currentData === undefined || currentData === null) {
				return defaultValue;
			}

			if (key.includes('[') && key.includes(']')) {
				const [mainKey, index] = key.split(/[[\]]/).filter((item) => item);
				if (currentData[mainKey]) {
					currentData = currentData[mainKey][parseInt(index, 10)];
				} else {
					return defaultValue;
				}
			} else {
				currentData = currentData[key];
			}
		}

		return currentData === undefined || currentData === null ? defaultValue : currentData;
	}

	@HostListener('document:mousemove', ['$event'])
	onMouseMove(e: MouseEvent) {
		if (e.target instanceof HTMLElement) {
			const ele = e.target as HTMLElement;
			const tar = ele.closest('.gradient-track') as HTMLElement;
			if (tar) {
				let rect = tar.getBoundingClientRect();
				let x = e.clientX - rect.left;
				let y = e.clientY - rect.top;
				tar.style.setProperty('--x', x + 'px');
				tar.style.setProperty('--y', y + 'px');
			}
		}
	}

	checkHasGradientTracking(element: HTMLElement): HTMLElement | undefined {
		if (element.tagName === 'body') {
			return undefined;
		}
		if (element.classList.contains('gradient-track')) {
			return element;
		} else if (element.parentElement !== null) {
			return this.checkHasGradientTracking(element.parentElement);
		}
		return undefined;
	}

	private isLocaleAdjustmentsMade(): boolean {
		const languageOptIn = this.cookies.get('lg-opt-in');
		return Boolean(languageOptIn);
	}
}
