import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DocumentComponent } from '@arematics/core/legal-document';

@Component({
	selector: 'app-legal',
	standalone: true,
	imports: [CommonModule, DocumentComponent],
	template: `<div class="defaultContainer minContainer"><a-document></a-document></div>`,
	styles: [],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class LegalComponent {}
