import { Component } from '@angular/core';
import { PromotionService } from '../../_service/promotion.service';
import { Promotion } from '../../_model/promotion';
import { ActivatedRoute } from '@angular/router';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.scss']
})
export class HomeComponent {
	java: Promotion;
	jwtBlog!: any;
	primeBlog!: any;
	constructor(private promotion: PromotionService, activatedRoute: ActivatedRoute) {
		this.java = this.promotion.getAll()[0];
		this.jwtBlog = activatedRoute.snapshot.data['entry2'];
		this.primeBlog = activatedRoute.snapshot.data['entry'];
	}
}
