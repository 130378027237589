import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class ResolverstateService {
	private stateSource = new BehaviorSubject<boolean>(false);
	state$ = this.stateSource.asObservable();

	setState(isLoading: boolean) {
		this.stateSource.next(isLoading);
	}

	get state() {
		return this.stateSource;
	}
}
