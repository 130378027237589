import { Inject, Pipe, PipeTransform, PLATFORM_ID } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { isPlatformBrowser } from '@angular/common';

@Pipe({
	name: 'imagePipe'
})
export class ImagePipe implements PipeTransform {
	constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {}

	transform(url: string): Observable<string> {
		if (isPlatformBrowser(this.platformId)) {
			// If we're on the browser, fetch the image data and return a blob URL
			return this.http.get(url, { responseType: 'blob' }).pipe(
				map((val: any) => URL.createObjectURL(val)),
				catchError((error: HttpErrorResponse) => of(''))
			);
		} else {
			// If we're on the server, just return the URL directly
			return of(url);
		}
	}
}
