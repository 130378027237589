import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IconComponent } from '@arematics/core/icon';
import { FormsModule } from '@angular/forms';
import { AnimateModule } from 'primeng/animate';
import { FlyInTop } from '../../_helper/animation';

@Component({
	selector: 'app-confirm-slider',
	standalone: true,
	imports: [CommonModule, IconComponent, FormsModule, AnimateModule],
	templateUrl: './confirm-slider.component.html',
	styleUrls: ['./confirm-slider.component.scss'],
	animations: [FlyInTop(200)],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmSliderComponent {
	finished: boolean = false;
	sliderValue: number = 1;
	@Input() finishDuration = 1500;
	@Output() successEmitter: EventEmitter<void> = new EventEmitter<void>();

	updatePosition() {
		if (this.sliderValue >= 99) {
			this.finished = true;
			setTimeout(() => this.successEmitter.emit(), this.finishDuration);
		}
	}

	resetSlider() {
		if (this.sliderValue < 99) {
			this.sliderValue = 1;
		}
	}
}
