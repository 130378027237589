import { Injectable } from '@angular/core';
import { CrudEndpointBase } from '@arematics/core/api';
import { ArematicsDataService } from './arematics-data.service';
import { environment } from '../../environments/environment';
import { FeedbackService, FeedbackVote } from '@arematics/core/feedback';
import { Observable } from 'rxjs';

@Injectable()
export class FeedbackVoteService
	extends CrudEndpointBase<FeedbackVote, ArematicsDataService>
	implements FeedbackService
{
	private readonly rootUrl = environment.data_api;

	constructor(private httpClient: ArematicsDataService) {
		super(httpClient, 'feedback/votes');
	}

	deleteFeedbackVote(vote: FeedbackVote): Observable<void> {
		return super.deleteByEntity(vote);
	}

	getFeedbackVote(id: number): Observable<FeedbackVote> {
		return this.service.get(this.path + '/byFeedback/' + id);
	}

	updateFeedbackVote(vote: FeedbackVote): Observable<FeedbackVote> {
		return super.save(vote);
	}
}
