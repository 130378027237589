import { APP_ID, ErrorHandler, isDevMode, LOCALE_ID, NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { HomeModule } from './pages/home/home.module';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ConfirmationService, MessageService, PrimeNGConfig } from 'primeng/api';
import { KeycloakLoadingModule } from './keycloak.loading.module';
import { RoutingModule } from './app.router';
import { PromotionService } from './_service/promotion.service';
import { DatePipe, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AddHeaderInterceptor } from './_helper/request.interceptor';
import { RouterOutlet } from '@angular/router';
import { KeycloakAuthGuard, KeycloakService } from '@arematics/keycloak-angular';
import { ImagePipe } from './_helper/image.pipe';
import { provideClientHydration, TransferState, withNoHttpTransferCache } from '@angular/platform-browser';
import { MarkdownModule } from 'ngx-markdown';
import { ToastModule } from 'primeng/toast';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmSliderComponent } from './components/confirm-slider/confirm-slider.component';
import { LocaleInterceptor } from './_helper/locale.interceptor';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { translateBrowserLoaderFactory } from './_helper/translate-browser.loader';
import { HeaderModule } from '@arematics/core/header';
import { FooterModule } from '@arematics/core/footer';
import { CookiesModule } from '@arematics/core/cookies';
import { ButtonComponent } from '@arematics/core/button';
import { IconComponent } from '@arematics/core/icon';
import { ArematicsConfig, DarkModeService, LegalService, NameService, SecurePipe } from '@arematics/core/api';
import { SeoService } from '@arematics/core/seo';
import { CustomPreloadingStrategy } from './_helper/custom.preload';
import { GlobalErrorHandler } from './_helper/error.handler';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { PopupService } from './_service/popup.service';
import { TranslationService } from './_service/translation.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SkeletonModule } from 'primeng/skeleton';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TranslateFeatureService } from '@arematics/core/translate-feature';
import { FEEDBACK_SERVICE } from '@arematics/core/feedback';
import { FeedbackVoteService } from './_service/feedback.vote.service';
import { ArematicsDataService } from './_service/arematics-data.service';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { BlogService } from './_service/blog.service';

@NgModule({
	declarations: [AppComponent],
	imports: [
		MarkdownModule.forRoot(),
		HttpClientModule,
		RoutingModule,
		BrowserAnimationsModule,
		HeaderModule,
		FooterModule,
		HomeModule,
		ServiceWorkerModule.register('/ngsw-worker.js', {
			enabled: !isDevMode()
		}),
		CookiesModule,
		KeycloakLoadingModule,
		ToastModule,
		ConfirmDialogModule,
		ButtonComponent,
		IconComponent,
		ConfirmSliderComponent,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: translateBrowserLoaderFactory,
				deps: [HttpClient, TransferState]
			}
		}),
		RouterOutlet,
		SkeletonModule,
		ProgressSpinnerModule,
		DropdownModule,
		FormsModule
	],
	providers: [
		CustomPreloadingStrategy,
		{ provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' },
		provideClientHydration(withNoHttpTransferCache()),
		{
			provide: ErrorHandler,
			useClass: environment.production ? GlobalErrorHandler : ErrorHandler,
			deps: [PopupService]
		},
		{ provide: APP_ID, useValue: 'arematics-learning' },
		{ provide: LocationStrategy, useClass: PathLocationStrategy },
		{
			provide: HTTP_INTERCEPTORS,
			useClass: AddHeaderInterceptor,
			multi: true,
			deps: [KeycloakService]
		},
		{ provide: 'REQUEST', useValue: '' },
		{
			provide: TranslationService,
			useClass: TranslationService,
			deps: [TranslateService, ArematicsConfig, PrimeNGConfig, LOCALE_ID]
		},
		{
			provide: FEEDBACK_SERVICE,
			useClass: FeedbackVoteService,
			deps: [ArematicsDataService]
		},
		KeycloakAuthGuard,
		PromotionService,
		SsrCookieService,
		MessageService,
		SecurePipe,
		ImagePipe,
		DatePipe,
		NameService,
		BlogService,
		ConfirmationService,
		TranslateFeatureService,
		DarkModeService,
		LegalService,
		{
			provide: HTTP_INTERCEPTORS,
			useClass: LocaleInterceptor,
			multi: true
		},
		{ provide: 'environment', useValue: environment }
	],
	bootstrap: [AppComponent]
})
export class AppModule {
	constructor(private seo: SeoService) {
		this.seo.updateDescription(
			$localize`:@@description:Discover Arematics Learning with our modern courses or take a look at our blog`
		);
	}
}
