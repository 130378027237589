import { Injectable } from '@angular/core';
import { CourseService } from './course.service';
import { catchError, Observable } from 'rxjs';
import { MultiChoiceResult, QuestionRun, QuestionRunResultPackage } from '../_model/multi.choice.data';
import { MultiChoice } from '@arematics/core/api';

@Injectable({
	providedIn: 'root'
})
export class RunsService {
	private basePath: string = 'learning/';

	constructor(private courses: CourseService) {}

	create(force: boolean = false): Observable<QuestionRun> {
		return this.courses.current.postNoBody(this.basePath + 'run?force=' + force);
	}

	getAllRuns(): Observable<QuestionRun[]> {
		return this.courses.current.get(this.basePath + 'run/all');
	}

	getRun(runId: number, courseId?: number): Observable<QuestionRun> {
		if (courseId) {
			return this.courses.get(`course/${courseId}/learning/run/${runId}`);
		}
		return this.courses.current.get(this.basePath + `run/${runId}`);
	}

	fetchCurrentRun(): Observable<QuestionRun> {
		return this.courses.current.get(this.basePath + 'run').pipe(catchError((err) => this.create()));
	}

	getRunChoiceResult(runId: number, choice: MultiChoice) {
		return this.courses.current.get(this.basePath + `run/${runId}/${choice.id}`);
	}

	setRunChoiceResult(runId: number, data: MultiChoiceResult) {
		return this.courses.current.post(this.basePath + `run/${runId}/result`, data);
	}

	checkRun(runId: number, courseId?: number): Observable<QuestionRunResultPackage> {
		if (courseId) {
			return this.courses.get(`course/${courseId}/learning/run/${runId}/check`);
		}
		return this.courses.current.get(this.basePath + `run/${runId}/check`);
	}
}
