<a-image-container [imageSrc]="promotion.image"
                     [justify]="promotion.justify"
                     align="align-items-center"
                     containerClass="border-round-lg dark-theme p-4 noPosition">
  <h2 class="mb-1 text-4xl" style="letter-spacing: 3px;">{{promotion.title}}</h2>
  <h3 *ngIf="promotion.subTitle" class="text-center mb-2 mt-0 text-2xl font-normal text-color-secondary">{{promotion.subTitle}}</h3>
  <div>
    <a class="text-xl link"
       [routerLink]="['/course/' + promotion.courseId + '/promotion']"
       i18n="@@course.promote.information">Learn more</a>
    <a class="text-xl link ml-4"
       *ngIf="promotion.released"
       [routerLink]="['/course/' + promotion.courseId]"
       i18n="@@course.promote.buy">Buy</a>
  </div>
</a-image-container>
