import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { DefaultHttpService } from '@arematics/core/api';

@Injectable({
	providedIn: 'root'
})
export class MailingDataService extends DefaultHttpService {
	constructor(http: HttpClient) {
		super(environment.mailing_api, http);
	}
}
