import { ErrorHandler } from '@angular/core';
import { PopupService } from '../_service/popup.service';

export class GlobalErrorHandler implements ErrorHandler {
	constructor(private popup: PopupService) {}
	handleError(error: any) {
		if (error.status && error.status === 429) {
			this.popup.sendErrorTranslated('notifications.slowDown');
		}
	}
}
