import { Injectable } from '@angular/core';
import { Observable, retry, timer } from 'rxjs';
import { PaymentDataService } from './payment.data.service';
import { LazyLoadEvent } from 'primeng/api';
import { HttpParams } from '@angular/common/http';
import { Order } from '../_model/order';
import { Page } from '@arematics/core/api';
import { OrderExtension } from '../_model/order.extension';
import { NavigationExtras, Router } from '@angular/router';

const retryConfig = { count: 5, delay: 4000 };

@Injectable({
	providedIn: 'root'
})
export class OrderService {
	constructor(private payment: PaymentDataService, private router: Router) {}

	getOrders(event: LazyLoadEvent, params?: HttpParams): Observable<Page<Order>> {
		return this.payment.paging('orders', event, params);
	}

	getOrderExtension(id: number): Observable<OrderExtension> {
		return this.payment.get('orders/' + id + '/extended');
	}

	getOrderBySession(sessionId: String): Observable<Order> {
		return this.payment.get('orders/bySession/' + sessionId);
	}

	validateSession(sessionId: string) {
		return this.payment.getSessionStatus(sessionId);
	}

	loadOrder(sessionId: string) {
		return this.getOrderBySession(sessionId).pipe(retry(retryConfig));
	}

	navigate(path: string, extras?: NavigationExtras) {
		void this.router.navigate([path], extras);
	}

	navigateToCheckout() {
		this.navigate('/checkout');
	}

	navigateToRetryPayment() {
		this.navigate('/checkout/pay', { queryParams: { retry: true } });
	}

	delay(time: number, callback: Function) {
		timer(time).subscribe(() => callback());
	}
}
