import { Injectable } from '@angular/core';
import { Promotion } from '../_model/promotion';
import { Observable, of, throwError } from 'rxjs';

@Injectable()
export class PromotionService {
	private promotions: Promotion[] = [
		{
			courseId: 3,
			released: true,
			title: 'Java Beginner',
			subTitle: 'Become an expert in a world of AI content',
			image: '/assets/courses/promotion/java_beginner/preview.webp',
			justify: 'justify-content-center',
			size: 'col-12',
			textColor: 'white',
			position: 0
		}
	];

	constructor() {}

	getAll() {
		return this.promotions;
	}

	findPromotion(courseId: number | undefined): Observable<Promotion> {
		if (!courseId) {
			throw throwError(() => 'Could not find promotion');
		}
		const result = this.promotions.find((promotion) => promotion.courseId === courseId);
		if (!result) {
			throw throwError(() => 'Could not find promotion');
		}
		return of(result);
	}
}
