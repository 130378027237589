import { animate, AnimationTriggerMetadata, style, transition, trigger } from '@angular/animations';

const timingString: string = 'ms ease-out';
const transformYZero: string = 'translateY(0)';

export function FlyInTop(timing: number = 200): AnimationTriggerMetadata {
	return trigger('flyInTop', [
		transition(':enter', [
			style({ opacity: 0, transform: 'translateY(-100%)' }),
			animate(timing + timingString, style({ opacity: '1', transform: transformYZero }))
		])
	]);
}

export function CollapseIn(timing: number = 200): AnimationTriggerMetadata {
	return trigger('collapseIn', [
		transition(':enter', [
			style({ opacity: 0, height: '0' }),
			animate(timing + timingString, style({ opacity: '1', height: '*' }))
		])
	]);
}

export function CollapseOut(timing: number = 200): AnimationTriggerMetadata {
	return trigger('collapseOut', [
		transition(':leave', [
			style({ opacity: 1, height: '*' }),
			animate(timing + timingString, style({ opacity: '0', height: '0' }))
		])
	]);
}

export function FlyOutTop(timing: number = 200): AnimationTriggerMetadata {
	return trigger('flyOutTop', [
		transition(':leave', [
			style({ opacity: 1, transform: transformYZero }),
			animate(timing, style({ opacity: '0', transform: 'translateY(-100%)' }))
		])
	]);
}

export function FlyOutBottom(timing: number = 200): AnimationTriggerMetadata {
	return trigger('flyOutBottom', [
		transition(':leave', [
			style({ opacity: 1, transform: transformYZero }),
			animate(timing, style({ opacity: '0', transform: 'translateY(100%)' }))
		])
	]);
}

export function FlyOutRight(timing: number = 200): AnimationTriggerMetadata {
	return trigger('flyOutRight', [
		transition(':leave', [
			style({ opacity: 1, transform: 'translateX(0)' }),
			animate(timing, style({ opacity: '0', transform: 'translateX(100%)' }))
		])
	]);
}
