import { inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivateFn, ResolveFn, Router, RouterStateSnapshot } from '@angular/router';
import { DefaultDataResolver, LegalService } from '@arematics/core/api';
import { MailProfile } from '../_model/mail.profile';
import { MailingDataService } from '../_service/mailing.data.service';
import { PaymentDataService } from '../_service/payment.data.service';
import { Course } from '../_model/course';
import { CourseService } from '../_service/course.service';
import { catchError, EMPTY, NEVER, switchMap } from 'rxjs';
import { BlogItem } from '../_model/blog.item';
import { BlogService } from '../_service/blog.service';
import { Promotion } from '../_model/promotion';
import { PromotionService } from '../_service/promotion.service';
import { Product } from '../_model/product';
import { OrderExtension } from '../_model/order.extension';
import { OrderService } from '../_service/order.service';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { map } from 'rxjs/operators';
import { ResolverstateService } from '../_service/resolverstate.service';
import { QuestionRunResultPackage } from '../_model/multi.choice.data';
import { RunsService } from '../_service/runs.service';
import { Organisation, OrganisationService } from '@arematics/core/organization';

export function onError() {
	void inject(Router).navigate(['/error']);
	return NEVER;
}

export const cookieGuardedRoute: CanActivateFn = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	cookieService: SsrCookieService = inject(SsrCookieService)
) => {
	if (cookieService.get('cookie-accepted') == 'true') {
		return true;
	}

	return inject(Router).parseUrl('/cookies');
};

export const orderExtendedResolver: ResolveFn<OrderExtension> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot
) => {
	const rss = inject(ResolverstateService);
	if (!inject(Router).getCurrentNavigation()?.extras.state?.re) {
		rss.setState(true);
	}
	return inject(OrderService)
		.getOrderExtension(Number(route.paramMap.get('id')))
		.pipe(
			map((data: OrderExtension) => {
				rss.setState(false);
				return data;
			}),
			catchError(() => onError())
		);
};
export const documentResolver: ResolveFn<Document> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	inject(LegalService)
		.getLegal(route.data.legalType)
		.pipe(catchError(() => onError()));
export const blogReleaseResolver: ResolveFn<BlogItem> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	inject(BlogService)
		.fetchReleaseById(Number(route.paramMap.get('id')))
		.pipe(catchError(() => onError()));
export const homePageResolve: ResolveFn<BlogItem> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	inject(BlogService)
		.fetchReleaseById(1)
		.pipe(catchError(() => onError()));
export const homePageResolve2: ResolveFn<BlogItem> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	inject(BlogService)
		.fetchReleaseById(2)
		.pipe(catchError(() => onError()));
export const blogEditResolver: ResolveFn<BlogItem> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) =>
	inject(BlogService)
		.fetchById(Number(route.paramMap.get('id')))
		.pipe(catchError(() => onError()));
export const courseResolver: ResolveFn<Course> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	courses: CourseService = inject(CourseService)
) =>
	courses.get(`course/${route.paramMap.get('id')}/owned`).pipe(
		map((data) => courses.updateItem(data)),
		catchError(() => onError())
	);
export const runResolver: ResolveFn<QuestionRunResultPackage> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	runService: RunsService = inject(RunsService)
) =>
	runService.getRun(Number(route.paramMap.get('runId')), Number(route.paramMap.get('id'))).pipe(
		switchMap((run) => runService.checkRun(run.runId, run.courseId)),
		catchError((err) => onError())
	);
export const coursePublicResolver: ResolveFn<Course> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	courses: CourseService = inject(CourseService)
) => courses.loadCourse(route.paramMap.get('id')).pipe(catchError(() => onError()));
export const saveCoursePublicResolver: ResolveFn<Course | undefined> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	courses: CourseService = inject(CourseService)
) => courses.loadCourse(route.paramMap.get('id')).pipe(catchError(() => EMPTY));
export const editableCourseResolver: ResolveFn<Course> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	courses: CourseService = inject(CourseService)
) =>
	courses.getCourseEdit(route.paramMap.get('id')!).pipe(
		map((response) => courses.updateItem(response.body!, response.headers)),
		catchError(() => onError())
	);

export const promotionResolver: ResolveFn<Promotion> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	promotion: PromotionService = inject(PromotionService)
) => promotion.findPromotion(Number(route.paramMap.get('id') || undefined)).pipe(catchError(() => onError()));

export const productResolver: ResolveFn<Product> = (
	route: ActivatedRouteSnapshot,
	state: RouterStateSnapshot,
	productService: PaymentDataService = inject(PaymentDataService)
) => productService.fetchProduct('course', Number(route.paramMap.get('id'))).pipe(catchError(() => onError()));

@Injectable({
	providedIn: 'root'
})
export class OrganisationResolver extends DefaultDataResolver<Organisation> {
	constructor(organizationService: OrganisationService, router: Router) {
		super(
			(route: ActivatedRouteSnapshot) => `organization/${route.paramMap.get('id')}`,
			organizationService,
			null,
			router
		);
	}
}

@Injectable({
	providedIn: 'root'
})
export class OrderResolver extends DefaultDataResolver<any> {
	constructor(payment: PaymentDataService, router: Router) {
		super((route: ActivatedRouteSnapshot) => `orders/${route.paramMap.get('id')}`, payment, null, router);
	}
}

@Injectable({
	providedIn: 'root'
})
export class MailHashResolver extends DefaultDataResolver<MailProfile> {
	constructor(dataService: MailingDataService) {
		super((route: ActivatedRouteSnapshot) => `mail/settings/${route.paramMap.get('id')}`, dataService, null);
	}
}
