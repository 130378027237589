import { Injectable } from '@angular/core';
import { BlogItem } from '../_model/blog.item';
import { ArematicsDataService } from './arematics-data.service';
import { LazyLoadEvent } from 'primeng/api';
import { HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Observable, switchMap } from 'rxjs';
import { environment } from '../../environments/environment';
import { map } from 'rxjs/operators';
import { ReadInformation } from '../_model/read.information';
import { CrudEndpointBase, Page } from '@arematics/core/api';

@Injectable()
export class BlogService extends CrudEndpointBase<BlogItem, ArematicsDataService> {
	private readonly rootUrl = environment.data_api;

	constructor(private httpClient: ArematicsDataService) {
		super(httpClient, 'blogs');
	}

	fetchPage(event: LazyLoadEvent, params?: HttpParams): Observable<Page<BlogItem>> {
		return super.fetchPage(event, params).pipe(
			map((page: any) => {
				page.content.forEach((item: any) => this.mapImage(item));
				return page;
			})
		);
	}

	fetchEditPage(event: LazyLoadEvent, params?: HttpParams): Observable<Page<BlogItem>> {
		return this.service.pagingPlain(this.path + '/edit', event, params).pipe(
			map((response: HttpResponse<Page<BlogItem>>) => {
				const page = response.body!;
				page.content.forEach((item: any) => this.mapImage(item, response.headers));
				return page;
			})
		);
	}

	fetchById(id: number): Observable<BlogItem> {
		return super.fetchByIdPlain(id).pipe(map((item) => this.mapImage(item.body!, item.headers)));
	}

	save(entity: BlogItem): Observable<BlogItem> {
		return super.savePlain(entity).pipe(map((item) => this.mapImage(item.body!, item.headers)));
	}

	createEntry(title: string, creator: string): Observable<BlogItem> {
		return this.service.postNoBody(this.path + `/create?title=${title}&creator=${creator}`);
	}
	fetchReleaseById(id: number): Observable<BlogItem> {
		return this.httpClient.get(this.path + '/release/' + id).pipe(map((item) => this.mapImage(item)));
	}

	releaseBlog(entry: BlogItem): Observable<void> {
		return this.httpClient.postNoBody(this.path + '/release/' + entry.id);
	}

	private mapImage(item: BlogItem, headers?: HttpHeaders): BlogItem {
		let signature = item.shortContent ? undefined : this.httpClient.getSignature('Blog-Image-' + item.id, headers);
		item.imageUrl = this.loadImage(item, signature);
		return item;
	}

	private loadImage(item: BlogItem, signature?: string): string {
		const addition = signature ? '?' + signature : '';
		if (item.imageReference) {
			return this.rootUrl + this.path + `/${item.id}/image${addition}`;
		}
		return '';
	}

	uploadImage(item: BlogItem, file: File): Observable<BlogItem> {
		const success = (result: ReadInformation) => {
			item.imageReference = result.latestRevision;
			return this.save(item);
		};
		return this.httpClient
			.postFile(this.path + '/' + item.id + '/image', file)
			.pipe(switchMap((result) => success(result)))
			.pipe(map((item) => this.mapImage(item)));
	}
}
