<div class="sliderContext border-round-3xl border-solid w-full p-overflow-hidden relative flex justify-content-center align-items-center">
  <input *ngIf="!finished"
         id="confirm" type="range"
         [(ngModel)]="sliderValue"
         (input)="updatePosition()"
         (mouseup)="resetSlider()"
         (touchend)="resetSlider()"
         min="1"
         max="100"
         class="h-full border-round-3xl w-full"/>
  <div *ngIf="finished" @flyInTop class="finishConfirm">
    <a-icon icon="check"></a-icon>
  </div>
</div>
