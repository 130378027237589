import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class LocaleInterceptor implements HttpInterceptor {
	constructor(@Inject(LOCALE_ID) private locale: string) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.params.has('translation')) {
			const clonedRequest = req.clone({ params: req.params.append('translation', this.locale) });
			return next.handle(clonedRequest);
		}

		return next.handle(req);
	}
}
