import { NgModule } from '@angular/core';
import { CommonModule, TitleCasePipe } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule as PrimeShare } from 'primeng/api';
import { FileSizePipe } from './file.size.pipe';
import { DurationPipe } from './duration.pipe';
import { ImagePipe } from './image.pipe';
import { PipesModule } from '@arematics/core/api';
import { AddCopyButtonDirective } from './add-copy-button.directive';

@NgModule({
	declarations: [FileSizePipe, DurationPipe, ImagePipe, AddCopyButtonDirective],
	imports: [PipesModule, CommonModule, FormsModule, PrimeShare],
	exports: [
		PipesModule,
		CommonModule,
		FormsModule,
		PrimeShare,
		FileSizePipe,
		DurationPipe,
		ImagePipe,
		AddCopyButtonDirective
	],
	providers: [TitleCasePipe]
})
export class SharedModule {}
