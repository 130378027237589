<div *ngIf="!localeAdjustmentsMade" class="dark-theme text-sm w-full" style="background-color: #171717;color: white;">
  <div class="container">
    <div class="p-4">
      <div class="flex justify-content-between align-items-center">
        <p>{{footer.switchLanguageText}}</p>
        <div class="flex align-items-center" style="gap: .8rem">
          <p-dropdown *ngIf="!mobile" [overlayOptions]="footer.dropdownOptions" [options]="footer.availableLanguages" [(ngModel)]="footer.selectedLanguage"></p-dropdown>
          <a-button [text]="true" [label]="footer.continueText" *ngIf="!mobile" (clicked)="footer.setLocaleAdjustmentsMade(footer.selectedLanguage)"></a-button>
          <a-icon class="text-2xl cursor-pointer" icon="xmark" (click)="footer.setLocaleAdjustmentsMade()"></a-icon>
        </div>
      </div>
      <div class="flex align-items-center" style="gap: .8rem">
        <p-dropdown *ngIf="mobile" [overlayOptions]="footer.dropdownOptions" [options]="footer.availableLanguages" [(ngModel)]="footer.selectedLanguage"></p-dropdown>
        <a-button [text]="true" severity="secondary" [label]="footer.continueText" *ngIf="mobile" (clicked)="footer.setLocaleAdjustmentsMade(footer.selectedLanguage)"></a-button>
      </div>
    </div>
  </div>
</div>
<a-header [items]="headerItems"
          [loginOverlayItems]="loginOverlayItems"
          [accountOverlayItems]="accountOverlayItems">
</a-header>
<div>
  <div *ngIf="loading" class="defaultContainer minContainer">
    <div class="w-full flex justify-content-center align-items-center mt-12 mb-12">
      <div class="flex flex-column align-items-center">
        <p-progressSpinner styleClass="w-8rem h-8rem"
                           strokeWidth="4"></p-progressSpinner>
        <h2 i18n="@@loading">Loading...</h2>
      </div>
    </div>
  </div>
  <router-outlet *ngIf="!loading"></router-outlet>
</div>
<a-footer #footer [items]="footerItems" [socials]="footerSocials" [legals]="footerLegals" class="footer"></a-footer>
<a-cookies *ngIf="!cookiesAccepted" (allowCookiesEmit)="cookiesAccepted = true"></a-cookies>
<p-toast [position]="'bottom-center'" key="bc" [preventOpenDuplicates]="true">
  <ng-template let-message pTemplate="message">
    <div class="p-toast-message-text">
      <div>
        <div class="p-toast-summary font-bold">{{message.summary}}</div>
        <div class="p-toast-detail">{{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>
<p-confirmDialog #cd rejectButtonStyleClass="p-button-text" [dismissableMask]="true">
  <ng-template pTemplate="header">
    <div class="confirmSlider">
      <app-confirm-slider (successEmitter)="cd.accept()"></app-confirm-slider>
    </div>
  </ng-template>
  <ng-template pTemplate="message">
    <p class="text-md"><b>{{cd.confirmation?.message}}</b></p>
  </ng-template>
  <ng-template pTemplate="footer"></ng-template>
</p-confirmDialog>
