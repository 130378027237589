import { Injectable } from '@angular/core';
import { MessageService } from 'primeng/api';
import { TranslationService } from './translation.service';

@Injectable({
	providedIn: 'root'
})
export class PopupService {
	constructor(private message: MessageService, private translation: TranslationService) {}

	sendSuccessTranslated(rootKey: string) {
		this.sendSuccess(rootKey + '.detail', rootKey + '.summary');
	}

	sendErrorTranslated(rootKey: string) {
		this.sendError(rootKey + '.detail', rootKey + '.summary');
	}

	sendMessageTranslated(rootKey: string, life: number = 1750, severity: any = 'success') {
		this.sendMessage(rootKey + '.detail', life, severity, rootKey + '.summary');
	}

	sendSuccess(detail: any, summary: any = 'notifications.success') {
		this.sendMessage(detail, 1750, 'success', summary);
	}

	sendError(detail: any, summary: any = 'notifications.error') {
		this.sendMessage(detail, 3500, 'error', summary);
	}

	sendMessage(detail: any, life: number = 1750, severity: any = 'success', summary: any = 'notifications.success') {
		detail = this.translation.get(detail);
		summary = this.translation.get(summary);
		this.message.add({ key: 'bc', severity, summary, detail, life });
	}

	async sharePage(title: string) {
		if (navigator.share) {
			void navigator.share({
				title: title,
				url: document.location.href
			});
		} else {
			// Fallback for desktop browsers using Clipboard API
			if (navigator.clipboard) {
				try {
					await navigator.clipboard.writeText(document.location.href);
					this.sendSuccess('forms.share.success');
				} catch (err) {
					this.sendError('forms.share.error');
				}
			}
		}
	}
}
