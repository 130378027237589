import { shared } from './env.share';

export const environment = {
	name: shared.name,
	production: true,
	localized: true,
	client_id: shared.client_id,
	realm_name: shared.realm_name,
	auth_api: 'https://auth.arematics.com/',
	payment_api: 'https://payment.arematics.com/api/v1/',
	mailing_api: 'https://mailing.arematics.com/api/v1/',
	data_api: 'https://data.arematics.com/api/v1/',
	user_api: 'https://user.arematics.com/api/v1/',
	wiki_api: 'https://course.arematics.com/api/v1/',
	video_api: 'https://video.arematics.com/api/v1/',
	image_api: 'https://images.arematics.com/api/v1/',
	orga_api: 'https://organization.arematics.com/api/v1/',
	google_recaptcha: '6Len8SUkAAAAAPZ1ALeTunOJk1sVE30w7HXHpLfQ'
};
