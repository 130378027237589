<div>
  <h1 class="visuallyhidden">Arematics</h1>
  <div class="defaultContainer pt-8 mb-6">
    <div class="grid">
      <div
        style="max-height: 600px"
        class="col-12 md:{{ java.size }}">
        <app-promote-display [promotion]="java"></app-promote-display>
      </div>
    </div>
  </div>
  <div class="container container-spacer mb-6">
    <h2 i18n>Blog</h2>
    <div class="p-grid p-nogutter grid grid-nogutter">
      <div class="col">
        <app-card
          *ngIf="jwtBlog"
          [image]="jwtBlog.imageUrl"
          [title]="jwtBlog.title"
          titleClass="text-lg mt-1"
          [text]="jwtBlog.shortContent"
          [url]="'/blogs/' + jwtBlog.id">
          <ng-template #footer>
            <p>{{ jwtBlog.updatedAt | date : 'mediumDate' }}</p>
          </ng-template>
        </app-card>
      </div>
      <div class="col">
        <app-card
          *ngIf="primeBlog"
          [image]="primeBlog.imageUrl"
          [title]="primeBlog.title"
          titleClass="text-lg mt-1"
          [text]="primeBlog.shortContent"
          [url]="'/blogs/' + primeBlog.id">
          <ng-template #footer>
            <p>{{ primeBlog.updatedAt | date : 'mediumDate' }}</p>
          </ng-template>
        </app-card>
      </div>
    </div>
  </div>
</div>
