import { APP_INITIALIZER, LOCALE_ID, NgModule, PLATFORM_ID } from '@angular/core';
import { environment } from '../environments/environment';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { KeycloakAngularModule, KeycloakService } from '@arematics/keycloak-angular';

function initializeKeycloak(keycloak: KeycloakService, platformId: any, localeId: any, document: Document) {
	if (!isPlatformBrowser(platformId)) {
		return () => {};
	}
	const localePath = localeId === 'en-US' ? '' : localeId + '/';
	const silentUrl = document.location.origin + '/' + localePath + 'assets/silent-check-sso.html';
	return () =>
		keycloak.init({
			config: {
				url: environment.auth_api,
				realm: environment.realm_name,
				clientId: environment.client_id
			},
			loadUserProfileAtStartUp: true,
			initOptions: {
				checkLoginIframe: true,
				onLoad: 'check-sso',
				silentCheckSsoRedirectUri: silentUrl,
				locale: localeId,
				silentCheckSsoFallback: true
			},
			bearerExcludedUrls: ['/tinymce', '/scripts', '/clients/public']
		});
}

@NgModule({
	imports: [KeycloakAngularModule],
	providers: [
		{
			provide: APP_INITIALIZER,
			useFactory: initializeKeycloak,
			multi: true,
			deps: [KeycloakService, PLATFORM_ID, LOCALE_ID, DOCUMENT]
		}
	]
})
export class KeycloakLoadingModule {
	constructor() {}
}
