import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { KeycloakService } from '@arematics/keycloak-angular';

@Injectable({
	providedIn: 'root'
})
export class AddHeaderInterceptor implements HttpInterceptor {
	constructor(private auth: KeycloakService) {}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		if (!req.headers.has('Authorization') && this.auth.isLoggedIn()) {
			const token = this.auth.getKeycloakInstance().token;
			if (token) {
				const clonedRequest = req.clone({ headers: req.headers.append('Authorization', token) });
				return next.handle(clonedRequest);
			}
		}
		return next.handle(req);
	}
}
