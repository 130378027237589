import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { CrudEndpointBase, DefaultHttpService } from '@arematics/core/api';
import { firstValueFrom, Observable } from 'rxjs';
import { CourseBase } from '../_model/courseBase';
import { Tag } from '../_model/tag';

export class TagService extends CrudEndpointBase<Tag, ArematicsDataService> {
	constructor(httpService: ArematicsDataService) {
		super(httpService, 'tags');
	}

	fetchAllByNameContains(contains: string): Observable<Tag[]> {
		return this.service.get(this.path + '/query?contains=' + contains);
	}
}

@Injectable({
	providedIn: 'root'
})
export class ArematicsDataService extends DefaultHttpService {
	currentCourseData: any;
	private readonly _tags: TagService;

	constructor(http: HttpClient) {
		super(environment.data_api, http);
		this._tags = new TagService(this);
	}

	get tags() {
		return this._tags;
	}

	async loadRatingData(course: CourseBase) {
		if (this.currentCourseData !== undefined && this.currentCourseData.id == course.id) {
			return Promise.resolve(this.currentCourseData);
		}
		const productString = 'course/' + course.id;
		const rating = await firstValueFrom(this.get(`feedback/${productString}/totalRating`));
		const feedbackAmount = await firstValueFrom(this.get(`feedback/${productString}/feedbackAmount`));
		this.currentCourseData = {
			id: course.id,
			rating,
			feedbackAmount
		};
		return Promise.resolve(this.currentCourseData);
	}

	getUsersLocale(defaultValue: string): string {
		if (typeof window === 'undefined' || typeof window.navigator === 'undefined') {
			return defaultValue;
		}
		const wn = window.navigator as any;
		let lang = wn.languages ? wn.languages[0] : defaultValue;
		lang = lang || wn.language || wn.browserLanguage || wn.userLanguage;
		return lang;
	}
}
