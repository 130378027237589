import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';
import { ArematicsConfig } from '@arematics/core/api';

@Injectable()
export class TranslationService {
	private translations: any;

	constructor(
		private translate: TranslateService,
		private arematicsConfig: ArematicsConfig,
		private config: PrimeNGConfig,
		@Inject(LOCALE_ID) locale: string
	) {
		this.translate.use(locale).subscribe(() => {
			this.translate.get('arematics').subscribe((r) => {
				this.translations = this.flattenObject(r);
				this.arematicsConfig.setTranslation(r);
			});
			this.translate.get('primeng').subscribe((r) => {
				this.config.setTranslation(r);
			});
		});
	}

	get(key: string) {
		return this.translations[key] || key;
	}

	private flattenObject(ob: any) {
		const toReturn: any = {};

		for (const i in ob) {
			if (!ob.hasOwnProperty(i)) {
				continue;
			}

			if (typeof ob[i] == 'object' && ob[i] !== null) {
				const flatObject = this.flattenObject(ob[i]);
				for (const x in flatObject) {
					if (!flatObject.hasOwnProperty(x)) {
						continue;
					}

					toReturn[i + '.' + x] = flatObject[x];
				}
			} else {
				toReturn[i] = ob[i];
			}
		}
		return toReturn;
	}
}
