import { Inject, Injectable, LOCALE_ID, PLATFORM_ID } from '@angular/core';
import { DefaultHttpService } from '@arematics/core/api';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { catchError, firstValueFrom, Observable, of } from 'rxjs';
import { getLocaleCurrencyCode, isPlatformBrowser } from '@angular/common';
import { Product } from '../_model/product';
import { Checkout } from '../_model/checkout';
import { PopupService } from './popup.service';
import { map } from 'rxjs/operators';

@Injectable({
	providedIn: 'root'
})
export class PaymentDataService extends DefaultHttpService {
	private readonly currency: string;
	private _checkout: Checkout;

	constructor(
		http: HttpClient,
		@Inject(LOCALE_ID) locale: string,
		@Inject(PLATFORM_ID) private platformId: Object,
		private popup: PopupService
	) {
		super(environment.payment_api, http);
		if (isPlatformBrowser(this.platformId)) {
			if (localStorage.getItem('checkout')) {
				this._checkout = JSON.parse(localStorage.getItem('checkout')!!);
			} else {
				this._checkout = { products: [], coupons: [] };
			}
		} else {
			this._checkout = { products: [], coupons: [] };
		}
		this.currency = getLocaleCurrencyCode(locale) || 'eur';
	}

	get checkout() {
		return this._checkout;
	}

	get currencyCode() {
		return this.currency;
	}

	hasProduct(product: Product): boolean {
		return this._checkout.products.some((item) => item.app == product.app && item.appId == product.appId);
	}

	async addProduct(product: Product): Promise<boolean> {
		if (this.hasProduct(product)) {
			this.popup.sendErrorTranslated('checkout.errors.itemExists');
			return false;
		}
		if (await firstValueFrom(this.ownsItem(product))) {
			this.popup.sendErrorTranslated('checkout.errors.hasItem');
			return false;
		}
		this.sendAnonymCheckoutAdd(product);
		this._checkout.products.push(product);
		this.saveCheckout();
		return true;
	}

	async checkItems(): Promise<void> {
		const newArray: Product[] = [];
		const items = Object.assign(newArray, this._checkout.products);
		for (const [i, item] of items.entries()) {
			const result = await firstValueFrom(this.ownsItem(item));
			if (result) {
				this._checkout.products.splice(i, 1);
				this.sendAnonymCheckoutRemove(item);
			}
		}
		this.saveCheckout();
		this.popup.sendErrorTranslated('checkout.errors.hasItems');
	}

	removeProduct(product: Product) {
		const index = this._checkout.products.indexOf(product);
		if (index >= 0) {
			this._checkout.products.splice(index, 1);
			this.sendAnonymCheckoutRemove(product);
			this.saveCheckout();
		}
	}

	saveCheckout() {
		localStorage.setItem('checkout', JSON.stringify(this._checkout));
	}

	public fetchProduct(app: string, appId: number): Observable<Product> {
		return this.get(`products/${app}/${appId}`);
	}

	clearCheckout() {
		this.checkout.products = [];
		this.saveCheckout();
	}

	private ownsItem(product: Product) {
		const { app, appId } = product;
		return this.getPlain(`products/${app}/${appId}/access`).pipe(
			map((response) => response.status === 202),
			catchError((err) => of(false))
		);
	}

	getSessionStatus(sessionId: string): Observable<any> {
		return this.get('orders/session/state/' + sessionId);
	}

	private sendAnonymCheckoutAdd(product: Product) {
		this.fetchSignature(649).subscribe((response: HttpResponse<void>) => {
			let signature = this.getSignature('Metric-Signature', response.headers);
			const addition = signature ? '?' + signature : '';
			this.post(`checkout${addition}`, {
				typeName: 'c.a',
				item: { app: product.app, appId: product.appId }
			}).subscribe(() => {});
		});
	}

	private sendAnonymCheckoutRemove(product: Product) {
		this.fetchSignature(701).subscribe((response: HttpResponse<void>) => {
			let signature = this.getSignature('Metric-Signature', response.headers);
			const addition = signature ? '?' + signature : '';
			this.post(`checkout${addition}`, {
				typeName: 'c.r',
				item: { app: product.app, appId: product.appId }
			}).subscribe(() => {});
		});
	}

	private fetchSignature(key: number): Observable<HttpResponse<void>> {
		return this.getPlain('checkout/' + key);
	}
}
