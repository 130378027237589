import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HomeComponent } from './home.component';
import { ButtonComponent } from '@arematics/core/button';
import { RouterLinkWithHref } from '@angular/router';
import { PromoteDisplayComponent } from '../course/promote/display/promote-display.component';
import { CardComponent } from '../../components/card/card.component';

@NgModule({
	declarations: [HomeComponent],
	imports: [
		CommonModule,
		ButtonComponent,
		RouterLinkWithHref,
		NgOptimizedImage,
		PromoteDisplayComponent,
		CardComponent
	],
	exports: [HomeComponent]
})
export class HomeModule {}
