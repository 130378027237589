import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Promotion } from '../../../../_model/promotion';
import { RouterLink } from '@angular/router';
import { ImageContainerComponent } from '@arematics/core/image-container';

@Component({
	selector: 'app-promote-display[promotion]',
	standalone: true,
	imports: [CommonModule, RouterLink, ImageContainerComponent],
	templateUrl: './promote-display.component.html',
	styleUrls: ['./promote-display.component.scss']
})
export class PromoteDisplayComponent {
	@Input() promotion!: Promotion;
}
