import { PreloadingStrategy, Route } from '@angular/router';
import { Observable, of } from 'rxjs';

export class CustomPreloadingStrategy implements PreloadingStrategy {
	preloadedModules: string[] = [];

	preload(route: Route, load: () => Observable<any>): Observable<any> {
		if (route && route.path && (!route.data || !route.data['blockPreload'])) {
			// Add the route path to our preloaded module list
			this.preloadedModules.push(route.path);
			return load();
		} else {
			return of(null);
		}
	}
}
