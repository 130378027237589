import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { RouterLink } from '@angular/router';
import { SharedModule } from '../../_helper/shared.module';

/**
 * Represents a card component that displays an image, title, and text or content.
 *
 * @example
 * ```html
 * <app-card
 *   title="Card Title"
 *   text="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
 *   url="/blog"
 *   image="/assets/card-image.jpg"
 * >
 *   <ng-template #body>
 *     <p>This is a custom body content.</p>
 *   </ng-template>
 *   <ng-template #footer>
 *     <p>This is a custom footer content.</p>
 *   </ng-template>
 * </app-card>
 * ```
 *
 * @Component
 */
@Component({
	selector: 'app-card',
	standalone: true,
	imports: [CommonModule, NgOptimizedImage, RouterLink, SharedModule],
	template: `
		<div
			class="card"
			[ngClass]="{ 'cursor-pointer': url }"
			[routerLink]="url ? [url] : []"
			[style]="backgroundImage ? 'background: url(' + image + ') no-repeat; background-size: cover;' : ''">
			<img
				*ngIf="image && !backgroundImage"
				[ngSrc]="image"
				[width]="imageWidth"
				[height]="imageHeight"
				[alt]="title + ' Blog Image'" />
			<div class="card_header" *ngIf="title">
				<h2 [ngClass]="titleClass">{{ title }}</h2>
			</div>
			<div class="card_body">
				<p *ngIf="text" [innerHtml]="text | safe : 'html'"></p>
				<ng-container *ngIf="!text && body">
					<ng-template [ngTemplateOutlet]="body"></ng-template>
				</ng-container>
			</div>
			<div class="card_footer">
				<ng-template [ngTemplateOutlet]="footer"></ng-template>
			</div>
		</div>
	`,
	styleUrls: ['./card.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
	@Input() image: string | undefined;
  @Input() imageWidth: number = 730
  @Input() imageHeight: number = 550
	@Input() backgroundImage = false;
	@Input() title: string | undefined;
	@Input() text: string | undefined;
	@Input() url: string | undefined;
	@Input() styleClass = '';
	@ContentChild('body') body: TemplateRef<any> | undefined;
	@ContentChild('footer') footer!: TemplateRef<any>;
  @Input() titleClass: string | string[] | Set<string> | { [p: string]: any } | null | undefined;
}
