import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { CustomPreloadingStrategy } from './_helper/custom.preload';
import { LegalComponent } from './pages/legal.component';
import { LegalType } from '@arematics/core/api';
import {
	blogReleaseResolver,
	documentResolver,
	homePageResolve,
	homePageResolve2
} from './_resolver/default.resolvers';

const routes: Route[] = [
	{
		path: '',
		data: {
			meta: {
				title: undefined
			}
		},
		component: HomeComponent,
		pathMatch: 'full',
		resolve: { entry: homePageResolve, entry2: homePageResolve2 }
	},
	{
		path: 'login',
		data: {
			meta: {
				title: 'Login'
			}
		},
		loadChildren: () => import('./pages/login/login.module').then((m) => m.LoginModule)
	},
	{
		path: 'mails',
		data: {
			meta: {
				title: 'Mails'
			}
		},
		loadChildren: () => import('./pages/mailing/mailing.module').then((m) => m.MailingModule)
	},
	{
		path: 'privacy',
		data: {
			legalType: LegalType.PRIVACY,
			meta: {
				title: $localize`:@@privacyTitle:Privacy`
			}
		},
		resolve: { document: documentResolver },
		component: LegalComponent
	},
	{
		path: 'datenschutz',
		data: {
			legalType: LegalType.PRIVACY,
			meta: {
				title: $localize`:@@privacyTitle:Privacy`
			}
		},
		resolve: { document: documentResolver },
		component: LegalComponent
	},
	{
		path: 'tos',
		data: {
			legalType: LegalType.TOS,
			meta: {
				title: $localize`:@@tosTitle:ToS`
			}
		},
		resolve: { document: documentResolver },
		component: LegalComponent
	},
	{
		path: 'agb',
		data: {
			legalType: LegalType.TOS,
			meta: {
				title: $localize`:@@tosTitle:ToS`
			}
		},
		resolve: { document: documentResolver },
		component: LegalComponent
	},
	{
		path: 'revocation',
		data: {
			legalType: LegalType.REVOCATION,
			meta: {
				title: $localize`:@@footer.recall:Revocation Rights`
			}
		},
		resolve: { document: documentResolver },
		component: LegalComponent
	},
	{
		path: 'imprint',
		data: {
			meta: {
				title: $localize`:@@imprintTitle:Imprint`
			}
		},
		loadChildren: () => import('./pages/imprint.wrapper.module').then((m) => m.ImprintWrapperModule)
	},
	{
		path: 'impressum',
		data: {
			meta: {
				title: $localize`:@@imprintTitle:Imprint`
			}
		},
		loadChildren: () => import('./pages/imprint.wrapper.module').then((m) => m.ImprintWrapperModule)
	},
	{
		path: 'cookies',
		data: {
			meta: {
				title: $localize`:@@cookiesTitle:Cookies and coffee?`
			}
		},
		loadChildren: () =>
			import('./pages/cookieNotAccepted.wrapper.module').then((m) => m.CookieNotAcceptedWrapperModule)
	},
	{
		path: 'support',
		data: {
			meta: {
				title: $localize`:@@support.title:Support`,
				description: $localize`:@@supportDescription:Support`
			}
		},
		loadChildren: () => import('./pages/support/support.module').then((m) => m.SupportModule)
	},
	{
		path: 'about',
		data: {
			meta: {
				title: $localize`:@@about.title:About us`,
				description: $localize`:@@aboutDescription:About`
			}
		},
		loadChildren: () => import('./pages/about/about.module').then((m) => m.AboutModule)
	},
	{
		path: 'course',
		data: {
			meta: {
				title: $localize`:@@courses.title:Courses`,
				description: $localize`:@@courses.description:Courses`
			}
		},
		loadChildren: () => import('./pages/course/courses.module').then((m) => m.CoursesModule)
	},
	{
		path: 'orders',
		data: {
			meta: {
				title: 'Orders'
			}
		},
		loadChildren: () => import('./pages/orders/orders.module').then((m) => m.OrdersModule)
	},
	{
		path: 'checkout',
		data: {
			meta: {
				title: 'Checkout'
			},
			blockPreload: true
		},
		loadChildren: () => import('./pages/checkout/checkout.module').then((m) => m.CheckoutModule)
	},
	{
		path: 'feedback',
		data: {
			meta: {
				title: 'Feedback'
			}
		},
		loadChildren: () => import('./pages/feedback/feedback.module').then((m) => m.FeedbackModule)
	},
	{
		path: 'organisation',
		loadChildren: () => import('./pages/organization/organization.module').then((m) => m.OrganizationModule)
	},
	{
		path: 'creator',
		data: {
			meta: {
				title: 'Creator',
				description: $localize`:@@creatorDescription:Creator`
			}
		},
		loadChildren: () => import('./pages/creator/creator.module').then((m) => m.CreatorModule)
	},
	{
		path: 'account',
		data: {
			meta: {
				title: $localize`:@@account.title:account`
			}
		},
		loadChildren: () => import('./pages/account/account.module').then((m) => m.AccountModule)
	},
	{
		path: 'blogs',
		data: {
			meta: {
				title: 'Blogs',
				description: $localize`:@@blogsDescription:Blogs`
			}
		},
		loadChildren: () => import('./pages/blog/blog.module').then((m) => m.BlogModule)
	},
	{
		path: '**',
		pathMatch: 'full',
		data: {
			meta: {
				title: $localize`:@@notfoundTitle:Page not found`
			}
		},
		loadChildren: () => import('./pages/notfound.wrapper.module').then((m) => m.NotfoundWrapperModule)
	},
	{
		path: 'error',
		pathMatch: 'full',
		data: {
			meta: {
				title: $localize`:@@notfoundTitle:Page not found`
			}
		},
		loadChildren: () => import('./pages/notfound.wrapper.module').then((m) => m.NotfoundWrapperModule)
	}
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, {
			anchorScrolling: 'enabled',
			preloadingStrategy: CustomPreloadingStrategy
		})
	]
})
export class RoutingModule {}
