import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DefaultHttpService, Page, UtilsService } from '@arematics/core/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../../environments/environment';
import { CourseBase } from '../_model/courseBase';
import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams, HttpResponse } from '@angular/common/http';
import { Course } from '../_model/course';
import { CourseVideoPart } from '../_model/courseVideoPart';
import { DatabaseFile } from '../_model/databaseFile';
import { SearchComponent } from '../components/course/search/search.component';
import { PopupService } from './popup.service';

function sortParts(a: CourseVideoPart, b: CourseVideoPart) {
	const keyA = Number(a.key.split('-')[1]);
	const keyB = Number(b.key.split('-')[1]);
	return keyA - keyB;
}

@Injectable({
	providedIn: 'root'
})
export class CourseService extends DefaultHttpService {
	private item: BehaviorSubject<Course | undefined> = new BehaviorSubject<Course | undefined>(undefined);
	private currentService: BehaviorSubject<CurrentCourseService | undefined> = new BehaviorSubject<
		CurrentCourseService | undefined
	>(undefined);

	constructor(http: HttpClient, private popups: PopupService, private utils: UtilsService, private router: Router) {
		super(environment.wiki_api, http);
	}

	getCertificate(certId: string) {
		const observe = this.downloadFileFromResponse(certId, 'pdf');
		this.client()
			.get(environment.wiki_api + 'course/certificate/' + certId, {
				withCredentials: true,
				observe: 'response',
				responseType: 'blob'
			})
			.subscribe(observe);
	}

	updateItem(item: Course, headers?: HttpHeaders) {
		item.imageReferences.sort((a, b) => a.imageIndex - b.imageIndex);
		this.currentService.next(new CurrentCourseService(item, this.client()));
		item.imageUrls = this.fetchImageUrls(item, headers);
		return item;
	}

	getCourseEdit(id: string) {
		const url = this.root + `course/${id}/edit`;
		return this.client().get<Course>(url, { observe: 'response' });
	}

	get current(): CurrentCourseService | any {
		return this.currentService.value;
	}

	get currentSafe(): CurrentCourseService | undefined {
		return this.currentService.value;
	}

	loadCourse(id: any): Observable<Course> {
		return this.get(`course/${id}/public`).pipe(map((data) => this.updateItem(data)));
	}

	async createCourse(course: any) {
		this.post('course', course).subscribe({
			next: (course: any) => {
				this.router.navigate([`/creator/course/edit/${course.id}`]);
				this.popups.sendMessage('Course created');
			},
			error: () => {
				this.popups.sendMessage('Some properties are missing', 3500, 'error', 'Creation Failed');
			}
		});
	}

	fetchImageUrls(course: CourseBase, headers?: HttpHeaders): string[] {
		let signature = this.getSignature('Course-' + course.id, headers);
		const addition = signature ? '?' + signature : '';
		const rootUrl = environment.wiki_api + `course/${course.id}/image/`;
		course.imageReferences.sort((a, b) => a.imageIndex - b.imageIndex);
		return course.imageReferences.map((ref) => rootUrl + `${ref.id}/${ref.imageReferenceId}${addition}`);
	}

	async download(course: any) {
		this.utils.downloadAsJson(course, `course_data_${course.id}`);
	}

	/**
	 * @deprecated Use {@link PopupService#sendSuccess} instead
	 */
	sendSuccess(detail: any, summary: any = 'Success') {
		this.popups.sendMessage(detail, 1750, 'success', summary);
	}

	/**
	 * @deprecated Use {@link PopupService#sendError} instead
	 */
	sendError(detail: any, summary: any = 'Error') {
		this.popups.sendMessage(detail, 3500, 'error', summary);
	}

	preloadUrls(observer: Observable<HttpResponse<Page<any>>>): Observable<any> {
		return observer.pipe(
			map((response: HttpResponse<Page<CourseBase>>) => {
				const page = response.body!;
				page.content.forEach((item: any) => this.mapImages(item, response.headers));
				return page;
			})
		);
	}

	private mapImages(item: CourseBase, headers?: HttpHeaders): CourseBase {
		item.imageUrls = this.fetchImageUrls(item, headers);
		return item;
	}

	fetchFile(selectedNode: CourseVideoPart | undefined, file: DatabaseFile) {
		if (selectedNode && this.currentSafe) {
			const url = `learning/video/part/${selectedNode.key}/docs/fetch`;
			const observe = this.downloadFileFromResponse(file.name, file.type);
			this.current.postResponse(url, file).subscribe(observe);
		}
	}

	private downloadFileFromResponse(fileName: string, type: string) {
		const observe: Partial<any> = {
			next: (response: HttpResponse<any>) => {
				const blob = new Blob([response.body], { type });
				this.utils.downloadFile(blob, fileName);
			},
			error: (err: any) => {
				this.popups.sendError('user.download.loadError');
			}
		};
		return observe;
	}

	setFilters(params: HttpParams, component: SearchComponent): HttpParams {
		if (component.filter.levels) {
			const levels = component.filter.levels.map((level: any) => level.key).join(',');
			params = params.set('level', levels);
		}
		if (component.filter.verified) {
			params = params.set('verified', true);
		}
		if (component.filter.professional) {
			params = params.set('professional', true);
		}
		const duration = component.filter.duration;
		const price = component.filter.price;
		if (duration[0] != null) {
			params = params.set('totalDurationGreater', component.filter.duration[0]);
		}
		if (duration[1] != null) {
			params = params.set('totalDurationLess', component.filter.duration[1]);
		}
		if (price[0] != null) {
			params = params.set('priceGreater', component.filter.price[0]);
		}
		if (price[1] != null) {
			params = params.set('priceLess', component.filter.price[1]);
		}
		if (component.filter.selectedCategory) {
			params = params.set('category', component.filter.selectedCategory);
		}
		return params.set('priceEquals', true);
	}

	getFileIcon(fileType: string): string {
		const typeIconMap: any = {
			png: 'image',
			jpg: 'image',
			svg: 'image',
			jpeg: 'image',
			zip: 'file-zipper'
		};

		return typeIconMap[fileType] || `file-${fileType}`;
	}
}

class CurrentCourseService extends DefaultHttpService {
	constructor(private course: Course, http: HttpClient) {
		super(environment.wiki_api + `course/${course.id}/`, http);
	}

	putForm(url: any, resource: any): Observable<any> {
		return this.client().put(environment.wiki_api + `course/${this.course.id}/` + url, resource, {
			withCredentials: true
		});
	}

	putNoBody(url: any): Observable<any> {
		return this.client().put(environment.wiki_api + `course/${this.course.id}/` + url, null, {
			withCredentials: true
		});
	}

	postResponse(url: any, resource: any): Observable<any> {
		return this.client().post(environment.wiki_api + `course/${this.course.id}/` + url, resource, {
			withCredentials: true,
			observe: 'response',
			responseType: 'blob'
		});
	}

	createPart(parent?: CourseVideoPart): Observable<any> {
		const key = (parent ? parent.key + '-' : '') + String(this.nextFree(parent));
		return this.put('parts', key);
	}

	getCertificateInfo(): Observable<any> {
		return this.client().get(environment.wiki_api + `course/${this.course.id}/user/certificate`);
	}

	private nextFree(parent?: CourseVideoPart): any {
		return this.highestNumber(parent ? parent.children : this.course.parts) + 1;
	}

	private highestNumber(items: CourseVideoPart[]): number {
		return Math.max(
			...items.map((o) => {
				if (o.key.includes('-')) {
					return Number(o.key.split('-')[1]);
				}
				return Number(o.key);
			}),
			-1
		);
	}
}
